.radioLabel {
     background-color: #222266;
     color: #b0b0b0;
     border-radius: 5px;
     height: 70px;
     width: 130px;
}

.radio input[type="radio"]:checked+label {
     color: white;
     background-color: #0d6efd;
}