#progressbar {
     margin-bottom: 30px;
     overflow: hidden;
     counter-reset: step;
     margin-left: -10%;
}

#progressbar li {
     list-style-type: none;
     color: #000;
     text-transform: uppercase;
     font-size: 9px;
     width: 20%;
     float: left;
     position: relative;
}

#progressbar li:before {
     content: counter(step);
     counter-increment: step;
     width: 20px;
     line-height: 20px;
     display: block;
     font-size: 10px;
     color: #000;
     background: #ddd;
     border-radius: 10px;
     margin: 0 auto 5px auto;
}

#progressbar li:after {
     content: '';
     width: 100%;
     height: 2px;
     background: #ddd;
     position: absolute;
     left: -50%;
     top: 9px;
     z-index: -1;
}

#progressbar li:first-child:after {
     content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
     background: conic-gradient(from 180deg, #1B4078, #229FA7);
     color: white;
     font-weight: bold;
}

#progressbar li.active {
     font-weight: bold;
}