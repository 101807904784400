.zoomIn {
     transform: scale(0);
     animation: zoomIn 0.5s forwards;
}

@keyframes zoomIn {
     0% {
          transform: scale(0);
     }

     100% {
          transform: scale(1);
     }
}

.status {
     max-width: 500px;
     background-color: #222222;
     padding: 20px;
     margin-left: auto;
     margin-right: auto;
     border-radius: 5px;
}

.sucBtn {
     max-width: 250px;
     border-radius: 4px;
     margin-left: auto;
     margin-right: auto;
     padding: 5px 0px;
     color: #bbffbb;
     background-color: green;
}

.sucBtn:hover {
     color: green;
     background-color: #bbffbb;
}

.decBtn {
     max-width: 250px;
     border-radius: 4px;
     margin-left: auto;
     margin-right: auto;
     padding: 5px 0px;
     color: #ffbbbb;
     background-color: red;
}

.othDec:hover {
     color: red;
     background-color: #ffbbbb;
}

.othBtn {
     max-width: 250px;
     border-radius: 4px;
     margin-left: auto;
     margin-right: auto;
     padding: 5px 0px;
     color: #222222;
     background-color: #cccccc;
}

.othBtn:hover {
     color: #cccccc;
     background-color: #222222;
}