.small-text{
     font-size: small;
}
.smaller-text{
     display: block;
     font-size: 0.7rem;
     line-height: 1;
}
.social{
     height: fit-content;
}
.sc-card{
     height: 100px;
}