.small-text {
     font-size: small;
}

.social {
     height: fit-content;
}

/* .sc-card {
     height: 150px;
} */

.profile-card {
     border-bottom-left-radius: 30px;
     border-bottom-right-radius: 30px;
     padding-top: 1px;
}


.connect-title {
     font-size: 1.2rem;
     top: 10px;
     right: 10px;
}

.connect-subtitle {
     font-size: 0.7rem;
     top: 35px;
     right: 10px;
}

.connect-address {
     font-size: 0.65rem;
     bottom: 40px;
     right: 10px;
     width: 180px;

}

.connect-hours {
     font-size: 0.65rem;
     bottom: 10px;
     right: 10px;
}

.connect-profile {
     height: 90px;
     width: 90px;
     overflow: hidden;
     background-position: center;
     background-size: cover;
     top: 65px;
     left: 10px;
}


.connect-location {
     font-size: 11px;
}

.connect-subtitle {
     font-size: 14px;
}

.connect-social {
     height: 100px;
}

.social-name,
.social-value {
     font-size: 10px;
}

.share-contact {
     position: fixed;
     bottom: 40px;
     right: 20px;
}

.connectBtn {
     height: 70px;
     width: 70px;
     background: rgba(0, 0, 0, 0.6);
     color: #fff;
}

.connectBtn:hover {
     color: #fff;
     background: rgba(0, 0, 0, 0.9);
}

.contact-image {
     height: 100px;
     width: 100px;
     background-position: center;
     background-size: cover;
}

.vl {
     border-left: 1px solid white;
     height: 180px;
     position: absolute;
     left: 90px;
     top: 10px;
     margin-left: -3px;

}