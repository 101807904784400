/* self defined colors */
.vecugo-black {
     background-color: #333333;
     color: #fff0f0;
}

.homepage * {
     font-family: 'Inter', sans-serif;
}

.vc-white {
     color: #F5F5F7;
}

.vb-white {
     background-color: #F5F5F7;
}

.zero-black {
     background-color: #000000;
     color: #fff0f0;
}

/* self defined colors */
/* Swipe Components settings */
.nav-top {
     height: 4rem;
     background-color: #eeeeee;
}

/* Swipe Components settings */


@import url('https: //fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
     margin: 0;
     font-family: 'Poppins', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}

* {
     -webkit-transition-property: all;
     -moz-transition-property: all;
     -o-transition-property: all;
     transition-property: all;
     -webkit-transition-duration: .1s;
     -moz-transition-duration: .1s;
     -o-transition-duration: .1s;
     transition-duration: .1s;
     -webkit-transition-timing-function: linear;
     -moz-transition-timing-function: linear;
     -o-transition-timing-function: linear;
     transition-timing-function: linear;
}

/* General CSS */

/* Negative Margins */
.mt-n1 {
     margin-top: -10px;
}

.mt-n2 {
     margin-top: -20px;
}

.mt-n3 {
     margin-top: -30px;
}

.mt-n4 {
     margin-top: -40px;
}

.mt-n5 {
     margin-top: -50px;
}

/* Shadows */
.shadow-mini {
     box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.shadow-midi {
     box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.2);
}

.shadow-maxi {
     box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.4);
}

/* Colors */
/* _________________________Text________________________ */
.tgray-10 {
     color: #eeeeee;
}

.tgray-20 {
     color: #dddddd;
}

/* _______________________Background______________________ */
.bggray-10 {
     background-color: #eeeeee;
}

.bggray-20 {
     background-color: #dddddd;
}

/* Text Align */
.t-end {
     text-align: end;
}

.t-center {
     text-align: center;
}

.t-start {
     text-align: start;
}

/* Font size */
/* __________________text____________________ */
.text-mini {
     font-size: 8.5px;
}

.text-midi {
     font-size: 12px;
}

.text-maxi {
     font-size: 18px;
}

/* PAGW SPECIFIC */
.main_page {
     height: 100vh;
     overflow-y: scroll;
}

@media (min-width: 768px) {
     .main_page {
          margin-left: 300px;
     }
}

.side_bar {
     z-index: 10;
     position: fixed;
     height: 100vh;
     width: 300px;
     overflow: hidden;
     top: 0;
     left: 0;
     overflow-x: hidden;
}

.probar {
     max-width: 300px;
     border-left: solid .2px #888888;
     border-right: solid .2px #888888;
}

.tabs_page {
     max-width: 520px;
     border-left: solid .2px #888888;
     border-right: solid .2px #888888;
     height: 100%;
}

button.className=,
div.className=,
a.className= {
     padding: 10px;
     border-bottom-right-radius: 20px;
     border-top-right-radius: 20px;
     margin-bottom: 10px;
     color: #000000;
     text-decoration: none;
}

.className=:hover {
     background-color: #eee;
     color: #000000;
}

.className=>span {
     margin-left: 10px;
}

.className=:focus,
.className=.active,
.className=:active {
     background-color: #ccc;
}

/* Connect Page */
.connect-card {
     max-height: 300px;
}

.connect-profile {
     height: 100px;
     width: 100px;
}

.quick-link-card {
     position: relative;
     height: 80px;
     border: #ffffff solid 1px;
     background-color: #eeeeee;
     color: #000;
}

.quick_link_pulse {
     position: relative;
     height: 80px;
     border: #ffffff solid 1px;
     background-color: #eeeeee;
}

.quick-link-card:hover {
     position: relative;
     height: 80px;
     border: #000000 solid 1px;
     background-color: #333333 !important;
     color: #fff;
}



/* Login page */

#profile_hair {
     fill: #ffffff;
}

/* Contact Page */
.contact_card {
     cursor: pointer;
     background-color: #eeeeee;
}

.contact_card img {
     border: #bbb solid 1px;
}

.contact_card:hover {
     background-color: #bbb;
     color: #ffffff;
}

.contact_card:hover img {
     border: #fff solid 1px;
}


/* Dashboard Page */

.categories-section {
     overflow: auto;
     white-space: nowrap;
}

.categories-section::-webkit-scrollbar {
     width: 0;
}

.single-category {
     display: inline-block;
     text-align: center;
     padding-right: 50px;
     margin-bottom: 10px;
     text-decoration: none;
     color: #000000;
     border-bottom: solid 1px #000000;
     font-weight: normal;
}

.single-category:active,
.single-category:focus,
.single-category:hover {
     border-bottom: solid 2px #000000;
     font-weight: bold;
     color: #000000;
}

.single-category.active {
     border-bottom: solid 3px #000000;
     font-weight: bold;
     color: #000000;
}

/* EVENTS PAGE */
.latest-card {
     height: 250px;
}

.events_tab {
     font-size: 0.9em;
     cursor: pointer;
}

.events_tab a {
     color: #000;
     text-decoration: none;
}


.events_tab>.active {
     border-bottom: solid 2px black;
     font-weight: bold;
     background-color: #eee;
}

.inside_connect_tab>.active {
     background-color: #000;
     color: #fff;
     border-radius: 20px;
}

/* Font Sizes */
.ts-xxl {
     font-size: 2.0rem;
}

.ts-xl {
     font-size: 1.6rem;
}

.ts-lg {
     font-size: 1.3rem;
}

.ts-md {
     font-size: 0.9rem;
}

.ts-sm {
     font-size: 0.7rem;
}

.ts-xs {
     font-size: 0.5rem;
}

.ts-xxs {
     font-size: 0.4rem;
}

.truncate {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 55px;
}

.trunc {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 100%;
}

.truncate-sm {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 125px;
}

.truncate-md {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 200px;
}

.truncate-lg {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: calc(100% - 20px);
}

textarea:hover,
input:hover,
input:active,
input:focus,
input,
input-group,
select,
textarea:active,
textarea:focus,
select:hover,
select:active,
select:focus,
label:focus {
     outline: 0px !important;
     -webkit-appearance: none;
     appearance: none;
     box-shadow: none !important;
     border: #000000 solid 1px;
}


a {
     color: #000000;
}

.nav-link:active,
.nav-link:hover,
.nav-link:focus {
     font-weight: bold;
}

.menuBtn {
     position: absolute;
     color: #ccc;
     background-color: black;
     width: 50px;
     height: 50px;
     border-radius: 50%;
     border: solid 2px #ccc;
}


.menuBtn>.material-symbols-rounded {
     font-size: 30px;
}

.slideUp {
     border-top: 2px solid #ccc;
     border-top-left-radius: 30px;
     border-top-right-radius: 30px;
     transition: height 600ms;
}

.dropdown-menu {
     background-color: rgba(0, 0, 0, 0.9);
     border: solid white 1px;
}

.dropdown-menu .dropdown-item {
     color: white;
}

.dropdown-menu .dropdown-item:hover {
     background-color: rgb(50, 50, 50);
}

.dropdown-menu hr {
     border-color: white;
}



/* Aninations Here */
.animate {
     animation-duration: 0.5s;
     -webkit-animation-duration: 0.5s;
     animation-fill-mode: both;
     -webkit-animation-fill-mode: both;
}

@keyframes pulse {
     0% {
          opacity: .3;
     }

     50% {
          opacity: .7;
     }

     100% {
          opacity: .3;
     }
}

@keyframes slideIn {
     0% {
          transform: translateY(2rem);
          opacity: 0;
     }

     100% {
          transform: translateY(3rem);
          opacity: 1;
     }

     0% {
          transform: translateY(2rem);
          opacity: 0;
     }
}

@-webkit-keyframes slideIn {
     0% {
          -webkit-transform: transform;
          -webkit-opacity: 0;
     }

     100% {
          -webkit-transform: translateY(3rem);
          -webkit-opacity: 1;
     }

     0% {
          -webkit-transform: translateY(2rem);
          -webkit-opacity: 0;
     }
}

@keyframes fadeIn {
     0% {
          display: none;
          opacity: 0;
     }

     1% {
          display: block;
     }

     100% {
          display: block;
          opacity: 1;
     }
}

@keyframes fadeOut {
     0% {
          display: block;
          opacity: 1;
     }

     99% {
          display: block;
     }

     100% {
          display: none;
          opacity: 0;
     }
}

@-webkit-keyframes slide-in-bottom {
     0% {
          -webkit-transform: translateY(1000px);
          transform: translateY(1000px);
          opacity: 0;
     }

     100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
          opacity: 1;
     }
}

@keyframes slide-in-bottom {
     0% {
          -webkit-transform: translateY(1000px);
          transform: translateY(1000px);
          opacity: 0;
     }

     100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
          opacity: 1;
     }
}

@-webkit-keyframes slide-out-top {
     0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
          opacity: 1;
     }

     100% {
          -webkit-transform: translateY(-1000px);
          transform: translateY(-1000px);
          opacity: 0;
     }
}

@keyframes slide-out-top {
     0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
          opacity: 1;
     }

     100% {
          -webkit-transform: translateY(-1000px);
          transform: translateY(-1000px);
          opacity: 0;
     }
}

@-webkit-keyframes slit-in-vertical {
     0% {
          -webkit-transform: translateZ(-800px) rotateY(90deg);
          transform: translateZ(-800px) rotateY(90deg);
          opacity: 0;
     }

     54% {
          -webkit-transform: translateZ(-160px) rotateY(87deg);
          transform: translateZ(-160px) rotateY(87deg);
          opacity: 1;
     }

     100% {
          -webkit-transform: translateZ(0) rotateY(0);
          transform: translateZ(0) rotateY(0);
     }
}

@keyframes slit-in-vertical {
     0% {
          -webkit-transform: translateZ(-800px) rotateY(90deg);
          transform: translateZ(-800px) rotateY(90deg);
          opacity: 0;
     }

     54% {
          -webkit-transform: translateZ(-160px) rotateY(87deg);
          transform: translateZ(-160px) rotateY(87deg);
          opacity: 1;
     }

     100% {
          -webkit-transform: translateZ(0) rotateY(0);
          transform: translateZ(0) rotateY(0);
     }
}

@-webkit-keyframes swing-in-top-fwd {
     0% {
          -webkit-transform: rotateX(-100deg);
          transform: rotateX(-100deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 0;
     }

     100% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 1;
     }
}

@keyframes swing-in-top-fwd {
     0% {
          -webkit-transform: rotateX(-100deg);
          transform: rotateX(-100deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 0;
     }

     100% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 1;
     }
}

@-webkit-keyframes vibrate-1 {
     0% {
          -webkit-transform: translate(0);
          transform: translate(0);
     }

     20% {
          -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px);
     }

     40% {
          -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px);
     }

     60% {
          -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
     }

     80% {
          -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
     }

     100% {
          -webkit-transform: translate(0);
          transform: translate(0);
     }
}

@keyframes vibrate-1 {
     0% {
          -webkit-transform: translate(0);
          transform: translate(0);
     }

     20% {
          -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px);
     }

     40% {
          -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px);
     }

     60% {
          -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
     }

     80% {
          -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
     }

     100% {
          -webkit-transform: translate(0);
          transform: translate(0);
     }
}



.pulse {
     animation: pulse .7s infinite;
}

.slideIn {
     -webkit-animation-name: slideIn;
     animation-name: slideIn;
}

.slide-in-bottom {
     -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
     animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-out-top {
     -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
     animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slit-in-vertical {
     -webkit-animation: slit-in-vertical 0.45s ease-out both;
     animation: slit-in-vertical 0.45s ease-out both;
}

.swing-in-top-fwd {
     -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
     animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.vibrate-1 {
     -webkit-animation: vibrate-1 0.3s linear infinite both;
     animation: vibrate-1 0.3s linear infinite both;
}


/* loading indicator */
.loading-indicator {
     height: 93vh;
}

.spinner-circle {
     left: calc(50% - 30px);
     top: calc(50% - 30px);
     width: 60px;
     height: 60px;
}

.spinner-icon {
     border-radius: 50%;
     left: calc(50% - 25px);
     top: calc(50% - 25px);
     width: 50px;
     height: 50px;
}

.spinner-icon-sm {
     border-radius: 50%;
     left: calc(50% - 13px);
     top: calc(50% - 16px);
     width: 26px;
     height: 26px;
}

.loading-text {
     width: 120px;
     text-align: center;
     top: calc(50% + 40px);
     left: calc(50% - 60px);
}

.spinner-dot {
     position: absolute;
     top: 10px;
     right: 3px;
     height: 6px;
     width: 6px;
}

.spinner-border {
     --bs-spinner-border-width: 0.1px;
}

.profile-options {
     right: 15px;
}

/* Error 404 */

.error-404-icon {
     left: calc(50% - 80px);
     top: calc(30% - 40px);
     width: 160px;
}

.error-404 {
     height: 91vh;
}

.error-404-message {
     width: 300px;
     top: calc(30% + 50px);
     left: calc(50% - 150px);
}

.error-404-link {
     text-decoration: underline;
}


/* DashBoard Cards Styles */
.db-cards-content {
     font-size: 0.5rem;
     display: inline-block;
     line-height: 0.5rem;
     bottom: 10px;
}

.delete-social-card {
     top: -5px;
     left: -1px;
     outline: 1px solid black;
     font-size: xx-small;
     height: 15px;
     width: 15px;
}

/* Loading Modal */
.loading-modal {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.8);
}

.loading-rect {
     position: fixed;
     width: 100vw;
     top: calc(50% - 20px);
     background-color: #ffffff;
}

.loading-circle {
     left: calc(50% - 15px);
     top: calc(50% - 17px);
     width: 30px;
     height: 30px;
}

.loading-icon {
     border-radius: 50%;
     left: calc(50% - 13px);
     top: calc(50% - 16px);
     width: 26px;
     height: 26px;
}

.loading-modal-text {
     text-align: start;
     width: 120px;
     top: calc(50% - 20px);
}

.loading-dot {
     position: absolute;
     top: 3px;
     right: 3px;
     height: 3px;
     width: 3px;
}

/* Onboarding Page  */
.half-screen-image {
     background: #000000;
     height: 100vh;
     width: 100vw;
}

.login-screen-mobile {
     max-width: 770px;
     padding: 30px;
     background: center url('/public/assets/images/background/icons_bg.svg') no-repeat;
     background-size: cover;
}

/* .login-form {} */

@media (min-width: 768px) {
     .login-form {
          max-width: 500px;
          margin: auto;
     }
}



/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
     display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
     -ms-overflow-style: none;
     scrollbar-width: none;
     /* Firefox */
}

.xxs {
     display: inline-flex;
     line-height: 0.5rem;
     font-size: 0.5rem;
}

.xs {
     display: inline-flex;
     line-height: 0.8rem;
     font-size: 0.8rem;
}

.bg-grayd {
     background-color: #ddd;
}

/* Images Styling */

.dashboard-img {
     height: 70px;
     width: 70px;
}

.images {
     width: 100% !important;
     height: 100% !important;
     object-fit: cover !important;
     object-position: center !important;
}

.img-onboarding {
     width: 100%;
     height: 100%;
     object-fit: cover;
     object-position: top;
}

.sb-img {
     height: 70px;
     width: 70px;
     object-position: center;
}

/* Images Styling */


/* Connect Styling */
.connect-section {
     max-width: 450px;
     border-radius: 10px;
     -webkit-box-shadow: 0 0 10px rgba(150, 150, 150, 0.75);
     -moz-box-shadow: 0 0 10px rgba(150, 150, 150, 0.75);
     box-shadow: 0 0 10px rgba(150, 150, 150, 0.75);
     height: calc(100vh - 90px);
     overflow-x: hidden;
}

.connect-section-mobile {
     max-width: 450px;
     border-radius: 10px;
     -webkit-box-shadow: 0px -7px 10px -3px rgba(150, 150, 150, 0.75);
     -moz-box-shadow: 0px -7px 10px -3px rgba(150, 150, 150, 0.75);
     box-shadow: 0px -7px 10px -3px rgba(150, 150, 150, 0.75);
     height: calc(100vh - 90px);
     overflow-x: hidden;
}

.connect-cover {
     overflow: hidden;
     height: 250px;
     background-position: center;
     border-radius: 10px;
}

.connect-cover img {
     /* height: 100%; */
     width: 100%;
     object-fit: cover;
}

/* Connect Styling */

/* font-types */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aboreto&display=swap');
/* font-types */


/* font styling */
.italicize {
     /* font-style: italic; */
     font-family: 'Ubuntu Mono', monospace;
}

.connect_heading {
     font-size: 20px;
     font-weight: 500;
}

/* font styling */
.connect-list-view.opened {
     animation: slide-in-bottom .5s ease-out forwards;
     /* animation-delay: .5s; */
}

.connect-list-view.closed {
     animation: fadeOut .5s ease-out forwards;
     /* opacity: 0; */
}

.card-view.opened {
     animation: slide-in-bottom .5s ease-out forwards;
     /* opacity: 1; */
}

.card-view.closed {
     animation: fadeOut .5s ease-out forwards;
     /* opacity: 0; */
}

/* .social-cards:hover {
	animation: vibrate-1 .5s infinite forwards;
} */

/* contacts */
.contact-card:hover,
.contact-card:focus,
.contact-card:active {
     background-color: #000000;
     color: #ffffff;
}

.contact-card:hover a,
.contact-card:focus a,
.contact-card:active a {
     color: #ffffff;
}

.file-uploader-overlay.edit {
     background-color: rgba(0, 0, 0, 0.5);
     width: 100%;
     height: 100%;
}

.swiper-pagination {
     display: none;
}

/* _____________________________________________________________________________________________________________________________Font Sizes */
.ts-sm {
     font-size: 0.7rem;
}

.ts-xs {
     font-size: 0.5rem;
}

.ts-xxs {
     font-size: 0.4rem;
}



/* _____________________________________________________________________________________________________________________________EVENTS PAGE */
.latest_card {
     height: 300px;
     width: 100%;
     object-position: center;
     object-fit: cover;
}

.latest_card_pulse {
     width: 100%;
     height: 300px;
     border-radius: 5px;
     background-color: #999;
}

.event_card_pulse {
     width: 100%;
     height: 100px;
     border-radius: 5px;
     background-color: #999;
}

.attendees_4 {
     height: 50px;
     width: 100%;
}

.attendees_4 :nth-child(1) {
     left: 0;
}

.attendees_4 :nth-child(2) {
     left: 20px;
}

.attendees_4 :nth-child(3) {
     left: 40px;
}

.attendees_4 :nth-child(4) {
     left: 60px;
}

.attendees_4 :nth-child(5) {
     left: 80px;
}

.attendees_4 :nth-child(6) {
     left: 100px;
}

.attendees_4 :nth-child(7) {
     left: 120px;
}

.view_all {
     padding-top: 15px;
     padding-right: 10px;
     text-align: end;
}

.attendee_card {
     position: absolute;
     border-radius: 50%;
     height: 50px;
     width: 50px;
}

/* _____________________________________________________________________________________________________________________________Vcard Dashboard PAGE */
.nav_dasboard_tab::-webkit-scrollbar {
     width: 2px;
}

.navigator {
     /* background-color: white; */
     padding: 7px;
}

.navigator.back {
     background: linear-gradient(to left, rgba(200, 200, 255, 0), rgba(200, 200, 255, .7));
}

.navigator.forward {
     background: linear-gradient(to right, rgba(200, 200, 255, 0), rgba(200, 200, 255, .7));
}

.dashboard_tab {
     margin-left: 10px;
     padding: 7px 20px;
     border-radius: 10px;
     background: conic-gradient(from 180deg, #aaeeff, #eeeeee);
}

.dashboard_tab:active,
.dashboard_tab.active,
.dashboard_tab:focus,
.dashboard_tab:hover {
     background: conic-gradient(from 180deg, #1B4078, #229FA7);
     border-radius: 10px;
     color: white;
}

/* _____________________________________________________________________________________________________________________________ Chat PAGE */
.chat .chat-header {
     padding: 10px 10px;
     background: linear-gradient(to right, #192546, #1B2233);
     color: white;
}

.chat .chat-header img {
     background-color: #fff;
     float: left;
     border-radius: 50%;
     width: 40px;
     height: 40px;
     object-fit: cover;
     object-position: center;
}

.chat .chat-header .chat-about {
     float: left;
     padding-left: 10px
}

.chat .chat-history {
     padding: 10px;
}

.chat .chat-history ul {
     padding: 0
}

.chat .chat-history ul li {
     list-style: none;
     margin-bottom: 20px
}

.chat .chat-history ul li:last-child {
     margin-bottom: 0px
}

.chat .chat-history .message-data {
     margin-bottom: 5px
}

.chat .chat-history .message-data img {
     border-radius: 50%;
     width: 40px;
     height: 40px;
     object-fit: cover;
     object-position: center;
}

.chat .chat-history .message-data-time {
     color: #000;
     padding-left: 6px;
     padding-right: 6px;
     font-size: .7rem;
}

.chat .chat-history .message {
     color: #000;
     padding: 10px 10px;
     line-height: 15px;
     font-size: 12px;
     border-radius: 7px;
     display: inline-block;
     position: relative
}

.chat .chat-history .other-message {
     background: #aec3f8;
}

.chat .chat-history .user-message {
     background: #aef8d6;
}

.chat .chat-history .admin-message {
     background: #f8c4ae;
}

.chat .chat-message {
     padding: 10px 10px;
     background: linear-gradient(to right, #192546, #1B2233);
}

.clearfix:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0
}


/* Pulsing___________________________ */
.profile_image_pulse {
     width: 40px;
     height: 40px;
     border-radius: 50%;
     background-color: #999;
}

.profile_username_pulse {
     width: 140px;
     height: 24px;
     border-radius: 2px;
     background-color: #999;
}

.viewdashboard_pulse {
     width: 200px;
     height: 49px;
     border-radius: 10px;
     background-color: #999;
}

.connect_user_title {
     width: 100%;
     margin-top: 10px;
     height: 30px;
     border-radius: 2px;
     background-color: #999;
}

.connect_view_title {
     width: 150px;
     margin-bottom: 10px;
     height: 30px;
     border-radius: 2px;
     background-color: #ddd;
}

.connect_user_subtitle {
     width: 100%;
     margin-top: 10px;
     height: 20px;
     border-radius: 2px;
     background-color: #999;
}

.connect_view_subtitle {
     width: 150px;
     height: 20px;
     border-radius: 2px;
     background-color: #ddd;
}

.connect_user_description {
     width: 100%;
     margin-top: 10px;
     height: 5px;
     border-radius: 2px;
     background-color: #999;
}

.connect_view_description {
     width: 100%;
     margin-top: 10px;
     height: 5px;
     border-radius: 2px;
     background-color: #999;
}

.connect_profile_pulse {
     width: 150px;
     height: 150px;
     border-radius: 2px;
     background-color: #999;
}



/* Homepage___________________________ */
.isce_h1 {
     font-size: 3rem;
     line-height: 3rem;
}

.home_subtitle {
     font-size: 2rem;
     line-height: 2rem;
}

.disabled {
     color: #bbb !important;
     cursor: not-allowed;
     /* pointer-events: none; */
}

/* Modals___________________________ */
.modal {
     background: rgba(0, 0, 30, 0.6);
     backdrop-filter: blur(3px);
}


.modal-content {
     border: 1px solid rgba(0, 0, 100, 0.8);
     border-radius: 5px;
     box-shadow: 5px 5px 2px 0px rgba(180, 180, 255, 0.7);
     -webkit-box-shadow: 5px 5px 2px 0px rgba(180, 180, 255, 0.7);
     -moz-box-shadow: 5px 5px 2px 0px rgba(180, 180, 255, 0.7);
}

.elevate:focus {
     box-shadow: inset 3px 3px 4px 0px rgba(0, 0, 70, 0.7) !important;
}

.elevate:hover {
     transition: ease-in-out .3s;
     /* translate: -3px -3px; */
     box-shadow: 3px 3px 1px 0px rgba(0, 0, 70, 0.7);
     -webkit-box-shadow: 3px 3px 1px 0px rgba(0, 0, 70, 0.7);
     -moz-box-shadow: 3px 3px 1px 0px rgba(0, 0, 70, 0.7);
}

/* INPUT BOXES */
input:focus,
textarea:focus,
select:focus {
     box-shadow: inset 3px 3px 4px 0px rgba(0, 0, 70, 0.7) !important;
}

.noarrowdropdown::after {
     display: none;
}

.service_image_upload {
     overflow: hidden;
     border-radius: 15px;
     width: 180px;
     max-height: 180px;
     margin-left: auto;
     margin-right: auto;
     margin-top: 30px;
     /* border: solid .5px #aaa; */
     object-fit: cover;
     object-position: center;
}