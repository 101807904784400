.active[contenteditable="true"] {
     border-bottom: 1px solid #ccc;
     outline: none;
     /* padding: 10px; */
     /* font-size: 16px; */
}

.active[contenteditable="true"]:empty:before {
     content: attr(dataplaceholder);
     color: #ccc;
}